import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import api from '../helpers/api'
import TeamForm from '../components/TeamForm';
import LoginForm from '../components/LoginForm';
import { Link } from 'gatsby';
import { dates } from '../constants'

class VasTym extends React.Component {
    constructor() {
        super();
        this.state = {
            user: {},
            errors: [],
            successMessage: '',
            session: {
                jwt: '',
                id: '',
            },
        }

        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.jwtKey = 'navic3Session';
        this.idKey = 'navicId';
    }

    generateSession(jwt, id) {
        const payload = new FormData();
        payload.append( 'jwt', jwt );

        api.post( '/team/' + id, payload, ( response ) => {
            const teamData = response.data;
            if ( 'error' === teamData.status ) {
                this.setState({
                    errors: teamData.data
                })
            } else {
                this.setState({
                    errors: [],
                    user: teamData.data,
                    session: {
                        jwt: jwt,
                        id: id,
                    }
                });

                window.localStorage.setItem( this.jwtKey, jwt );
                window.localStorage.setItem( this.idKey, id );
            }
        } );
    }

    handleLogin(event) {
        event.preventDefault();
        const payload = new FormData(event.target);

        api.post( '/login', payload, ( response ) => {
            const data = response.data;
            if ( 'error' === data.status ) {
                this.setState({
                    errors: data.data
                });
            } else {
                this.generateSession( data.data.jwt, data.data.id );
            }
        } );
    }

    handleLogout(event) {
        event.preventDefault();

        window.localStorage.removeItem( this.jwtKey );
        window.localStorage.removeItem( this.idKey );

        this.setState({
            user: {},
            session: {
                jwt: '',
                id: '',
            }
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        const payload = new FormData(event.target);
        payload.append( 'jwt', this.state.session.jwt );

        if ( ! payload.has('sleep_lock') ) {
            payload.append( 'sleep_lock', 0 );
        }

        api.post( '/team/' + this.state.user.id + '/edit/', payload, ( response ) => {
            const data = response.data;
            if ( 'error' === data.status ) {
                this.setState({
                    errors: data.data
                });
            } else {
                this.setState({
                    errors: [],
                    successMessage: 'Údaje upraveny!',
                });
            }
        } );
    }


    componentDidMount() {
        const jwt = window.localStorage.getItem( this.jwtKey );
        const id = window.localStorage.getItem( this.idKey );

        if ( jwt ) {
            let session = this.state.session;
            session.jwt = jwt;

            this.setState({
                session: session
            });
            this.generateSession( jwt, id );
        }
    }

    render() {
        let formComponent, nextActions, teamInfo;
        if ( ! this.state.user.id ) {
            teamInfo = <></>
            if ( this.state.session.jwt ) {
                formComponent = <>
                    <p>Načítám údaje o Vašem týmu&hellip;</p>
                    <p>Pokud tuto zprávu vidíte už dlouho, zkuste se odhlásit a znovu přihlásit: <span class="anchor" onClick={this.handleLogout} onKeyPress={this.handleLogout} role="button" tabIndex="0">odhlásit se z webu</span>.</p>
                </>
            } else {
                formComponent = <>
                    <header className="major">
                        <h2>Přihlášení</h2>
                    </header>
				<LoginForm loginCallback={this.handleLogin} successMessage={this.state.successMessage} errors={this.state.errors} />
                    <p className="align-center"><Link to="/zapomenute-heslo">Zapomenuté heslo?</Link></p>
                </>
            }
            nextActions = <></>
        } else {
            let paymentInfo;
            if ( '1' === this.state.user.paid ) {
                paymentInfo = <div>
					<p>Startovné máte zaplaceno, těšíme se na vás na hře!</p>
					<ul>
						<li><a href="/posledni-informace-2021.pdf" target="_blank">Poslední informace před hrou (Navíc 3)</a></li>
						<li><a href="/webinfo" target="_blank">Odkaz na Webinfo</a></li>
					</ul>
                    <p>23. 6., 17.00: Update Posledního infa. Přidali jsme informaci do sekce Covid, že samotest pro účast také postačuje.</p>
				</div>
            } else {
                const paymentData = {
                    price: 400,
                    vs: `3${this.state.user.id.padStart(3, '0')}`,
                    account: '2700113785',
                    bank: '2010',
                    message: 'Startovné - hra Navíc',
                }
                const qrUrl = `//api.paylibo.com/paylibo/generator/czech/image?accountNumber=${paymentData.account}&bankCode=${paymentData.bank}&amount=${paymentData.price}&currency=CZK&vs=${paymentData.vs}&message=${encodeURIComponent(paymentData.message)}&size=200`;
                paymentInfo = <>
                    <p>Startovné {paymentData.price} Kč zaplaťte prosím do {dates.payment} do 12.00.</p>
                    <ul>
                        <li>Účet: {paymentData.account}/{paymentData.bank}</li>
                        <li>VS: {paymentData.vs}</li>
                        <li>Zpráva pro příjemce: {paymentData.message}</li>
                    </ul>
                    <img alt="QR platba" src={qrUrl} />
                </>
            }
            teamInfo = <>
                <header className="major">
                    <h2>Váš tým</h2>
                </header>
                {paymentInfo}
            </>
            formComponent = <>
                <header className="major">
                    <h2>Editace údajů</h2>
                </header>
                <TeamForm handleSubmit={this.handleSubmit} user={this.state.user} successMessage={this.state.successMessage} errors={this.state.errors} />
            </>
            nextActions = <>
                <p>Pokud chcete, můžete se taky <span class="anchor" onClick={this.handleLogout} onKeyPress={this.handleLogout} role="button" tabIndex="0">odhlásit z webu</span>.</p>
                <p>Pokud si přejete úplně zrušit svoji registraci, napište nám prosím na <a href="mailto:navic@sifrovacky.cz">navic@sifrovacky.cz</a>.</p>
            </>
        }

        return (
            <Layout>
                <SEO
                title="Váš tým"
                description={`Stránka týmu na hru Navíc, která se koná v Praze ${dates.game}.`}
                />

                <div id="main">
                    <section id="one">
                        <div className="inner">
                            {teamInfo}
                            {formComponent}
                            {nextActions}
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default VasTym
